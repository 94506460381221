<template>
  <v-container v-if="orgId == null || apiLoaded">

    <section class="form-wrap">
      <v-form ref="form">

        <v-row>
          <v-col cols="12">
            <v-select label="Тип организации" dense outlined clearable :items="legalEntityTypeDict" item-value="code"
              item-text="title" v-model="legalEntity.type"></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-text-field label="Фамилия" dense outlined v-model.trim="legalEntity.surname"
              :rules="nameRules" v-if="legalEntity.type === 'FL' || legalEntity.type === 'IP'" @change="onNameChange()"></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-text-field label="Имя" dense outlined v-model.trim="legalEntity.firstName"
              :rules="nameRules" v-if="legalEntity.type === 'FL' || legalEntity.type === 'IP'" @change="onNameChange()"></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-text-field label="Отчество" dense outlined v-model.trim="legalEntity.middleName"
              :rules="nameRules" v-if="legalEntity.type === 'FL' || legalEntity.type === 'IP'" @change="onNameChange()"></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-textarea label="Полное наименование" dense outlined v-model.trim="legalEntity.fullName"
              :readonly="legalEntity.type === 'FL' || legalEntity.type === 'IP'"></v-textarea>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-text-field label="Сокращенное наименование" dense outlined v-model.trim="legalEntity.shortName"
              :readonly="legalEntity.type === 'FL' || legalEntity.type === 'IP'"></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-select label="Тип клиента" dense outlined clearable :items="clientTypeDict" item-value="code"
              item-text="title" v-model="legalEntity.clientType"></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-text-field label="Регистрационный номер на zakupki.gov.ru" dense outlined v-model.trim="legalEntity.regnum"
              v-if="legalEntity.type === 'TCST'"></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-select label="Часовой пояс заказчика" dense outlined clearable :items="customerTimeZoneDict"
              item-value="code" item-text="title" v-model="legalEntity.customerTimeZone"
              v-if="legalEntity.type === 'TCST'">
            </v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-select label="Уровень" dense outlined clearable :items="customerLevelsDict" item-value="code"
              item-text="title" v-model="legalEntity.level" v-if="legalEntity.type === 'TCST'"></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-textarea label="Юридический адрес" dense outlined v-model.trim="legalEntity.postAddress"></v-textarea>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-textarea label="Фактический адрес" dense outlined v-model.trim="legalEntity.factAddress"></v-textarea>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-autocomplete label="Субъект РФ (по юр. адресу)" dense outlined clearable :items="regionsDict"
              item-value="code" item-text="title" v-model="legalEntity._regionId"></v-autocomplete>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-text-field label="ИНН" dense outlined v-model.trim="legalEntity.inn"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field label="КПП" dense outlined v-model.trim="legalEntity.kpp" :rules="kppRules" v-if="legalEntity.type !== 'FL'"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field label="ОГРН" dense outlined v-model.trim="legalEntity.ogrn" v-if="legalEntity.type==='ORG'||legalEntity.type==='TCST'"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field label="ОГРНИП" dense outlined v-model.trim="legalEntity.ogrnip" v-if="legalEntity.type==='IP'"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field label="ОКПО" dense outlined v-model.trim="legalEntity.okpo" v-if="legalEntity.type==='ORG'||legalEntity.type==='TCST'"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field label="Телефоны" dense outlined v-model.trim="legalEntity.phone"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field label="E-mail" dense outlined v-model.trim="legalEntity.email"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field label="Web-сайт" dense outlined v-model.trim="legalEntity.website"></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-textarea label="Комментарий" dense outlined v-model.trim="legalEntity.comment"></v-textarea>
          </v-col>
        </v-row>

        <v-row style="margin-bottom:20px;">
          <v-col cols="12">
            Информация о счете
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-text-field label="Наименование банка" dense outlined v-model.trim="legalEntity.bank"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field label="БИК" dense outlined v-model.trim="legalEntity.bik"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field label="Корреспондентский счет" dense outlined v-model.trim="legalEntity.correspondentAccountNum">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field label="Расчетный счет" dense outlined v-model.trim="legalEntity.paymentAccountNum"></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-checkbox label="Наша организация" dense outlined v-model.trim="legalEntity.isOurOrg"></v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field label="Префикс для шифров договоров" dense outlined v-model.trim="legalEntity.ourOrgCypherPrefix"
              v-if="legalEntity.isOurOrg"></v-text-field>
          </v-col>
        </v-row>


        <v-row>
          <v-col cols="12">
            <v-checkbox label="Проблемный заказчик" dense outlined v-model.trim="legalEntity.problematic"
              v-if="!legalEntity.isOurOrg"></v-checkbox>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-select label="Тип проблемного заказчика" dense outlined clearable :items="problemCustomerTypeDict"
              item-value="code" item-text="title" v-model="legalEntity.problemCustomerType"
              v-if="!legalEntity.isOurOrg && legalEntity.problematic"></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-textarea label="Организация, по проекту которой произошел спор" dense outlined
              v-model.trim="legalEntity.ourProblemOrg" v-if="!legalEntity.isOurOrg && legalEntity.problematic"></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-textarea label="Структурное подразделение" dense outlined v-model.trim="legalEntity.problemDivision"
              v-if="!legalEntity.isOurOrg && legalEntity.problematic"></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-textarea label="ФИО сотрудников заказчика" dense outlined v-model.trim="legalEntity.problemFIO"
              v-if="!legalEntity.isOurOrg && legalEntity.problematic"></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-textarea label="Должности сотрудников заказчика" dense outlined v-model.trim="legalEntity.problemPost"
              v-if="!legalEntity.isOurOrg && legalEntity.problematic"></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-textarea label="Проект, по которому произошел спор" dense outlined v-model.trim="legalEntity.problemProject"
              v-if="!legalEntity.isOurOrg && legalEntity.problematic"></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-textarea label="Основные причиты и факторы" dense outlined v-model.trim="legalEntity.problemReason"
              v-if="!legalEntity.isOurOrg && legalEntity.problematic"></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-textarea label="Ключевые этапы разбирательства" dense outlined v-model.trim="legalEntity.problemStages"
              v-if="!legalEntity.isOurOrg && legalEntity.problematic"></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-textarea label="Принятое решение" dense outlined v-model.trim="legalEntity.problemDecision"
              v-if="!legalEntity.isOurOrg && legalEntity.problematic"></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-textarea label="Даты спора" dense outlined v-model.trim="legalEntity.problemDates"
              v-if="!legalEntity.isOurOrg && legalEntity.problematic"></v-textarea>
          </v-col>
        </v-row>


        <div class="mt-6">
          <v-btn outlined @click="cancel()">Отменить</v-btn>
          <v-btn @click="save()">Сохранить</v-btn>
        </div>
      </v-form>
    </section>

  </v-container>
</template>


<script>
import api from '../../modules/api'
import { legalEntityTypeDict, clientTypeDict, customerLevelsDict, regionsDict, customerTimeZoneDict, problemCustomerTypeDict } from '@/modules/NSI'

export default {
  name: 'CounterpartyEditComponent',
  data() {
    return {
      orgId: null,
      tender: {},
      apiError: false,
      apiLoaded: false,
      legalEntityTypeDict: legalEntityTypeDict,
      clientTypeDict: clientTypeDict,
      customerLevelsDict: customerLevelsDict,
      regionsDict: regionsDict,
      customerTimeZoneDict: customerTimeZoneDict,
      problemCustomerTypeDict: problemCustomerTypeDict,

      legalEntity: {
        id: null,
        type: 'ORG',
        clientType: null,
        fullName: null,
        shortName: null,
        postAddress: null,
        factAddress: null,
        inn: null,
        kpp: null,
        phone: null,
        email: null,
        website: null,
        hasImage: null,
        bank: null,
        bik: null,
        correspondentAccountNum: null,
        paymentAccountNum: null,
        level: null,
        comment: null,
        isOurOrg: null,
        ourOrgCypherPrefix: null,
        _regionId: null,
        ogrn: null,
        okpo: null,
        firstName: null,
        middleName: null,
        surname: null,
        ogrnip: null,
        problematic: null,
        problemCustomerType: null,
        ourProblemOrg: null,
        problemDivision: null,
        problemFIO: null,
        problemPost: null,
        problemProject: null,
        problemReason: null,
        problemStages: null,
        problemDecision: null,
        problemDates: null,
        regnum: null,
        customerTimeZone: null
      },
      nameRules: [
        v => (v?.length <= 50) || 'Длина не должна превышать 50 символов'
      ],
      kppRules: [
        v => (v?.length <= 9) || 'КПП не должен превышать 9 символов'
      ]
    }
  },

  methods: {
    cancel() {
      if (this.orgId != null) {
        this.$router.push({ path: '/counterparty/' + this.orgId })
      } else {
        this.$router.push({ path: '/counterparties/' })
      }
    },

    onNameChange() {
      this.legalEntity.fullName = (this.legalEntity.type === 'IP' ? 'Индивидуальный предприниматель ' : '') +
        this.legalEntity.surname +
        (this.legalEntity.firstName ? ' ' + this.legalEntity.firstName : '') +
        (this.legalEntity.middleName ? ' ' + this.legalEntity.middleName : '')
      this.legalEntity.shortName = (this.legalEntity.type === 'IP' ? 'ИП ' : '') +
        this.legalEntity.surname +
        (this.legalEntity.firstName && this.legalEntity.firstName.length > 0 ? ' ' + this.legalEntity.firstName.charAt(0) + '.' : '') +
        (this.legalEntity.middleName && this.legalEntity.middleName.length > 0 ? ' ' + this.legalEntity.middleName.charAt(0) + '.' : '')
    },

    async save(){
      if (!this.$refs.form.validate()) {
        return;
      }
      this.deleteUnusedFields()
      this.legalEntity.region = this.legalEntity._regionId != null ? {id: this.legalEntity._regionId} : null
      console.log(JSON.stringify(this.legalEntity, null, 3))
      let req = this.orgId == null ? await api.postJson('/supmain/legalentities', this.legalEntity) :
                                      await api.putJson('/supmain/legalentities', this.legalEntity)
      console.log(req)
      if(req.ok) {
        this.$router.push('/counterparty/' + req.payload.id)
      }
    },

    //удаляет поля, которые не должны заполняться (например, огрнип для организаций)
    deleteUnusedFields(){
      if(this.legalEntity.type !=='TCST')
        this.legalEntity.regnum = this.legalEntity.customerTimeZoneDict = this.legalEntity.level = null
      if(this.legalEntity.isOurOrg)
        this.legalEntity.problematic = null
      else
        this.legalEntity.isOurOrg = this.legalEntity.ourOrgCypherPrefix = null
      if(!this.legalEntity.problematic)
        this.legalEntity.problematic = this.legalEntity.problemCustomerType = this.legalEntity.ourProblemOrg = this.legalEntity.problemDivision = this.legalEntity.problemFIO =
        this.legalEntity.problemPost = this.legalEntity.problemProject = this.legalEntity.problemReason = this.legalEntity.problemStages = this.legalEntity.problemDecision =
        this.legalEntity.problemDates = null
      if(this.legalEntity.type === 'FL')
        this.legalEntity.ogrn = this.legalEntity.ogrnip = this.legalEntity.okpo = this.legalEntity.kpp = null
      else if(this.legalEntity.type === 'IP')
        this.legalEntity.ogrn = this.legalEntity.okpo =  null
      else
        this.legalEntity.ogrnip = this.legalEntity.firstName = this.legalEntity.middleName = this.legalEntity.surname = null
    }
  },

  async beforeMount() {
    this.orgId = this.$route.params.id
    if (this.orgId != null) {
      let req = await api.get('/supmain/legalentities/' + this.orgId)
      if (req.ok) {
        this.legalEntity = req.payload
        this.legalEntity._regionId = this.legalEntity.region ? this.legalEntity.region.id : null
        this.apiLoaded = true
      } else {
        this.apiError = true
      }
    }
  }
}
</script>
